import * as React from "react"
import PropTypes from "prop-types"
import { useI18next, Link } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";

interface Props {
  siteTitle: string;
  showLogo: boolean;
}

const Header: React.FC<Props> = ({ siteTitle, showLogo }) => {
  const {languages, changeLanguage, language, path} = useI18next();

  return <header
    style={{
      margin: `0 auto`,
      padding: `var(--space-4) var(--size-gutter)`,
      display: `flex`,
      alignItems: `center`,
      justifyContent: `space-between`,
    }}
  >
    <Link
      to="/"
      style={{
        fontSize: `var(--font-sm)`,
        textDecoration: `none`,
      }}
    >
      {showLogo && <StaticImage
          src="../images/logo.png"
          loading="eager"
          width={350}
          quality={95}
          sizes="(min-width: 600px) 350px, 250px"
          formats={["auto", "webp", "avif"]}
          alt="RedFox enduro academy"
        />}
    </Link>

    <div>
      {languages.filter(lang => lang !== language).map(lang => <a
        href="#"
        key={lang}
        onClick={(e) => {
          e.preventDefault();
          changeLanguage(lang)
        }}
        >{lang}</a>)}
    </div>

  </header>
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
