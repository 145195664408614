import * as React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.scss"
import HomeMenuItem from "../components/HomeMenuItem/HomeMenuItem"
import { Trans, useTranslation } from "react-i18next"
import PageFooter from "../components/PageFooter/PageFooter"
import MarkdownHTML from "../components/MarkdownHTML"

const IndexPage = ({ data }) => {
  const { t } = useTranslation()

  return (
    <div>
      <Layout showLogo={false}>
        <Seo title="Red Fox Greece" description={t("site-description")} />

        <div className={styles.textCenter}>
          <StaticImage
            src="../images/logo.png"
            loading="eager"
            width={550}
            quality={95}
            sizes="(min-width: 600px) 350px, 250px"
            formats={["auto", "webp", "avif"]}
            alt="RedFox enduro academy"
            style={{ marginBottom: `var(--space-3)` }}
          />
          <span className={styles.welcomeText}>
            <Trans i18nKey="welcome-text">Welcome to your next</Trans>
          </span>
          <span className={styles.welcomeTextHighlight}>
            <Trans i18nKey="welcome-text-highlight">adventure!</Trans>
          </span>

          <div className={styles.introText}>
            <div className={styles.introDetails}>
              <MarkdownHTML data={data} name="intro_services" />

              <MarkdownHTML data={data} name="intro_recommended" />
            </div>
          </div>



          <div className={styles.mainMenu}>
            {data.sections.edges.map((edge, i) => {
              return (
                <div className={styles.mainMenuTile}>
                  <HomeMenuItem
                    key={i}
                    image={edge.node.value}
                    title={t(edge.node.value)}
                    subtext={t(`${edge.node.value}_subtext`)}
                    link={`/${edge.node.value}`}
                  ></HomeMenuItem>
                </div>
              )
            })}
          </div>
        </div>
      </Layout>
      <PageFooter />
    </div>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    sections: allSectionsJson {
      edges {
        node {
          value
        }
      }
    }
    allMarkdownRemark(filter: { frontmatter: { lang: { eq: $language } } }) {
      nodes {
        id
        frontmatter {
          name
          title
          lang
        }
        html
      }
    }
  }
`
