// extracted by mini-css-extract-plugin
export var intro = "index-module--intro--0Xx3G";
export var introDetails = "index-module--introDetails--7cew0";
export var introText = "index-module--introText--81V2M";
export var list = "index-module--list--xVkFx";
export var listItem = "index-module--listItem--j7y7O";
export var listItemDescription = "index-module--listItemDescription--49slj";
export var listItemLink = "index-module--listItemLink--rGMWg";
export var mainMenu = "index-module--mainMenu--bgV2Y";
export var mainMenuTile = "index-module--mainMenuTile--yT9jj";
export var textCenter = "index-module--textCenter--++JQ-";
export var welcomeText = "index-module--welcomeText--xchko";
export var welcomeTextHighlight = "index-module--welcomeTextHighlight--wxfFg";