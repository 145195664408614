import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Link } from "gatsby-plugin-react-i18next"
import React from "react"
interface Props {
  image: string
  title: string
  subtext: string
  link?: string;
}

import * as styles from "./HomeMenuItem.module.scss"

const HomeMenuItem: React.FC<React.PropsWithChildren<Props>> = ({
  image,
  subtext,
  title,
  link,
}) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: allFile(filter: { relativePath: { regex: "/^sections\/[^/]+.jpg/" } }) {
          nodes {
            name
            childImageSharp {
              fluid(quality: 60, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const imageData = data.desktop.nodes.find(f => f.name === image)
    .childImageSharp.fluid

  return (
    <Link to={link} className={styles.homeMenuItemWrapper}>
      <BackgroundImage
        Tag="div"
        className={styles.homeMenuItem}
        fluid={imageData}
        backgroundColor={`#040e18`}
      >
        <div className={styles.title}>{title}</div>
        <div className={styles.subtext}>{subtext}</div>
      </BackgroundImage>
    </Link>
  )
}

export default HomeMenuItem
